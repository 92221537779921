import React from "react"
import Layout from "../components/layout"


const StyleGuide = () => (
  <div>
<Layout>
    <p><span className="icon-time"></span><span>icon-time</span></p>

    <p><span className="icon-settings"></span><span>icon-settings</span></p>

    <p><span className="icon-pencil"></span><span>icon-pencil</span></p>

    <p><span className="icon-pin-marker"></span><span>icon-pin-marker</span></p>

    <p><span className="icon-ticket"></span><span>icon-ticket</span></p>

    <p><span className="icon-phone"></span><span>icon-phone</span></p>


    <p><span className="icon-calcul-contract"></span><span>icon-calcul-contract</span></p>

    <p><span className="icon-truck-assist"></span><span>icon-truck-assist</span></p>

    <p><span className="icon-card"></span><span>icon-card</span></p>

    <p><span className="icon-contact-end"></span><span>icon-contact-end</span></p>

    <p><span className="icon-inspection"></span><span>icon-inspection</span></p>

    <p><span className="icon-tire"></span><span>icon-tire</span></p>

    <p><span className="icon-questions"></span><span>icon-questions</span></p>

    <p><span className="icon-download"></span><span>icon-download</span></p>

    <p><span className="icon-chevron"></span><span>icon-chevron</span></p>

    <p><span className="icon-confirm-email"></span><span>icon-confirm-email</span></p>

    <p>
    <span className="icon-win icon-win-text icon-win-no-bg">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="text">BRONZE</span>
    </span>
    </p>

    <p><span className="icon-search-2"></span><span>icon-search-2</span></p>


    <p><span className="icon-marker"></span><span>icon-marker</span></p>


    <p><span className="icon-car"></span><span>icon-car</span></p>
    <p><span className="icon-car icon-car-new"></span><span>.icon-car.icon-car-new</span></p>


    <p><span className="icon-star"></span><span>icon-star</span></p>


    <p><span className="icon-dealer-favorite"></span><span>icon-dealer-favorite</span></p>


    <p><span className="icon-dealer"></span><span>icon-dealer</span></p>


    <p><span className="icon-help"></span><span>icon-help</span></p>


    <p><span className="icon-error"></span><span>icon-error</span></p>


    <p><span className="icon-globe"></span><span>icon-globe</span></p>


    <p><span className="icon-calendar-maintenance"></span><span>icon-calendar-maintenance</span></p>


    <p><span className="icon-reward"></span><span>icon-reward</span></p>


    <p><span className="icon-offers"></span><span>icon-offers</span></p>


    <p><span className="icon-inbox"></span><span>icon-inbox</span></p>


    <p><span className="icon-magazine"></span><span>icon-magazine</span></p>


    <p><span className="icon-calendar"></span><span>icon-calendar</span></p>


    <p><span className="icon-check"></span><span>icon-check</span></p>


    <p><span className="icon-eye"></span><span>icon-eye</span></p>


    <p><span className="icon-close"></span><span>icon-close</span></p>


    <p><span className="icon-search"></span><span>icon-search</span></p>


    <p><span className="icon-logout"></span><span>icon-logout</span></p>


    <p><span className="icon-contracts"></span><span>icon-contracts</span></p>


    <p><span className="icon-maintenance"></span><span>icon-maintenance</span></p>


    <p><span className="icon-recall"></span><span>icon-recall</span></p>


    <p><span className="icon-logo-nissan"></span><span>icon-logo-nissan</span></p>


    <p><span className="icon-extension-garanti"></span><span>icon-extension-garanti</span></p>


    <p><span className="icon-atelier"></span><span>icon-atelier</span></p>


    <p><span className="icon-assistance"></span><span>icon-assistance</span></p>


    <p><span className="icon-controle-technique"></span><span>icon-controle-technique</span></p>


    <p><span className="icon-contract-check"></span><span>icon-contract-check</span></p>


    <p><span className="icon-characteristics"></span><span>icon-characteristics</span></p>

</Layout>
  </div>
)

export default StyleGuide


